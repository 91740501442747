import { useScreenClass } from 'react-grid-system'

export function useScreenSize() {
  const screenClass = useScreenClass()

  const isMobile = screenClass === 'xs' || screenClass === 'sm' || screenClass === 'md'
  const isDesktop = screenClass === 'lg' || screenClass === 'xl' || screenClass === 'xxl' || screenClass === 'xxxl'

  return { isMobile, isDesktop, screenClass }
}
