import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Container, useScreenClass } from 'react-grid-system'
import Moment from 'react-moment'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'
import Box from 'styles/Box'
import Button from 'styles/Button'
import { H3, H4 } from 'styles/Heading'
import Text from 'styles/Text'
import Alert from 'styles/Alert'

import Alignment from 'styles/Alignment'
import { sameDay } from 'helpers/sameDay'
import { AccommodationType, AccommodationOptionType, QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

import AccommodationOption from './AccommodationOption'

const Accommodations: React.FC = () => {
  const { itineraryId }: any = useParams()
  const { accommodations, itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)

  if (!itinerary) {
    return null
  }

  return (
    <Container>
      {!accommodations?.length && (
        <Box m={0} mt="2rem" mb="3rem">
          <Alert>
            Aan deze reis zijn geen accommodaties toegevoegd. Wilt u een accommodatie reserveren, laat het ons dan
            vooral weten!
          </Alert>
        </Box>
      )}

      {accommodations?.length > 0 && (
        <>
          {itinerary?.status === 'open' && (
            <Box m={0} mt="4rem" mb="1rem">
              <p>
                Hebt u zelf een ander hotel gezien wat er niet tussen staat maar wat u toch interessant lijkt? Laat het
                ons weten dan passen we dit graag aan in de offerte.
              </p>
            </Box>
          )}

          {accommodations.map((accommodation: AccommodationType, index: number) => {
            const nights = moment(accommodation.endDate).diff(accommodation.startDate, 'days')
            const startDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!accommodation.startDate) return false
                return sameDay(new Date(dayDate), new Date(accommodation.startDate))
              }) + 1

            let endDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!accommodation.endDate) return false
                return sameDay(new Date(dayDate), new Date(accommodation.endDate))
              }) + 1
            if (!endDayIndex) endDayIndex = itinerary.days.length
            return (
              <React.Fragment key={index}>
                <Box m={0} mr="0.5rem" inline>
                  <Alignment v="baseline">
                    <Box m={0} mr="0.5rem" mt="2rem">
                      {!itinerary.hideDates && (
                        <H3>
                          <Moment format="D MMMM">{accommodation.startDate}</Moment>
                          <span> - </span>
                          <Moment format="D MMMM YYYY">{accommodation.endDate}</Moment>
                        </H3>
                      )}

                      {itinerary.hideDates && (
                        <H3>
                          Dag {startDayIndex}
                          {!!endDayIndex && (
                            <>
                              <span> - </span>
                              Dag {endDayIndex}
                            </>
                          )}
                        </H3>
                      )}
                    </Box>
                    {!itinerary.hideDates && (
                      <Text color="neutral" variant="light" inline>
                        ({nights} nacht{nights !== 1 && 'en'})
                      </Text>
                    )}
                  </Alignment>
                </Box>
                {accommodation.options.map((option: AccommodationOptionType) => {
                  const orderedByAmount = [...accommodation.options].sort((a, b) => a.sellAmount - b.sellAmount)

                  return (
                    <AccommodationOption
                      key={option.id}
                      accommodationId={accommodation.id}
                      orderedByAmount={orderedByAmount}
                      {...option}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </>
      )}

      <Box m={0} mt="1rem" mb="8rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={`/${itineraryId}/flights`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Vluchten
            </Button>
          </Link>
        </Box>

        <Link to={`/${itineraryId}/excursions`}>
          <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
            Excursies
          </Button>
        </Link>
      </Box>
    </Container>
  )
}

export default Accommodations
