/* eslint-disable no-case-declarations */

import {
  FETCH_QUOTE,
  FETCH_QUOTE_FAIL,
  FETCH_QUOTE_SUCCESS,
  SELECT_FLIGHT_SUCCESS,
  SELECT_ACCOMMODATION_SUCCESS,
  SELECT_EXCURSION_OR_EXTRA_SUCCESS,
  SELECT_RENTAL_SUCCESS,
  FETCH_HIGHLIGHTED_EXCURSIONS_AND_EXTRAS,
  FETCH_HIGHLIGHTED_EXCURSIONS_AND_EXTRAS_SUCCESS,
  FETCH_METHODS_SUCCESS,
  CREATE_PAYMENT,
} from 'actions/Quote'
import {
  QuoteStateType,
  FlightOptionType,
  AccommodationOptionType,
  ExcursionOptionType,
  RentalOptionType,
  PaymentMethodType,
} from 'types'

export const INITIAL_QUOTE: QuoteStateType = {
  isFetching: false,
  fetchingError: null,
  data: {
    itinerary: null,
    flights: [],
    accommodations: [],
    excursions: [],
    rentals: [],
    extras: [],
    summary: null,
    invoices: [],
    highlightedExcursions: [],
    highlightedExtras: [],
    paymentMethods: [],
  },
}

const Quote: any = (state: QuoteStateType = INITIAL_QUOTE, action: any) => {
  switch (action.type) {
    case FETCH_QUOTE: {
      return {
        ...state,
        isFetching: true,
        fetchingError: null,
      }
    }

    case FETCH_QUOTE_FAIL: {
      return {
        ...state,
        isFetching: false,
        fetchingError: action.error,
      }
    }

    case FETCH_QUOTE_SUCCESS: {
      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      }
    }

    case SELECT_FLIGHT_SUCCESS: {
      const { serviceId, optionId } = action.meta.previousAction.payload.request.data

      const updatedFlights = state.data.flights.map((flight) => {
        if (flight.id === serviceId) {
          flight.options = flight.options.map((option: FlightOptionType) => ({
            ...option,
            selected: option.id === optionId,
          }))
          return flight
        }
        return flight
      })

      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          flights: updatedFlights,
        },
      }
    }

    case SELECT_ACCOMMODATION_SUCCESS: {
      const { serviceId, optionId } = action.meta.previousAction.payload.request.data

      const updatedAccommodation = state.data.accommodations.map((accommodation) => {
        if (accommodation.id === serviceId) {
          accommodation.options = accommodation.options.map((option: AccommodationOptionType) => ({
            ...option,
            selected: option.id === optionId,
          }))
          return accommodation
        }
        return accommodation
      })

      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          accommodations: updatedAccommodation,
        },
      }
    }

    case SELECT_EXCURSION_OR_EXTRA_SUCCESS: {
      const { serviceId, optionId } = action.meta.previousAction.payload.request.data
      const updatedExcursions = state.data.excursions.map((excursion) => {
        if (excursion.id === serviceId) {
          const excursionOptions = excursion.options.map((option: ExcursionOptionType) => {
            if (option.id === optionId) {
              return {
                ...option,
                selected: !option.selected,
              }
            }
            return option
          })

          return {
            ...excursion,
            options: excursionOptions,
          }
        }
        return excursion
      })

      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          excursions: updatedExcursions,
        },
      }
    }

    case SELECT_RENTAL_SUCCESS: {
      const { serviceId, optionId } = action.meta.previousAction.payload.request.data

      const updatedRental = state.data.rentals.map((rental) => {
        if (rental.id === serviceId) {
          rental.options = rental.options.map((option: RentalOptionType) => ({
            ...option,
            selected: option.id === optionId,
          }))
          return rental
        }
        return rental
      })

      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          rentals: updatedRental,
        },
      }
    }
    case FETCH_HIGHLIGHTED_EXCURSIONS_AND_EXTRAS: {
      return {
        ...state,
        isFetching: true,
        fetchingError: null,
      }
    }
    case FETCH_HIGHLIGHTED_EXCURSIONS_AND_EXTRAS_SUCCESS: {
      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          highlightedExcursions: action.payload.data.Excursions.map(
            (excursion: any) =>
              Object.fromEntries(
                Object.entries(excursion).map(([key, value]) => [
                  `${key[0].toLowerCase()}${key.slice(1)}`
                    .replace('__c', '')
                    .replace('name', 'title')
                    .replace('units', 'participants'),
                  value,
                ])
              ) as ExcursionOptionType
          ),
          highlightedExtras: action.payload.data.Extras.map(
            (extra: any) =>
              Object.fromEntries(
                Object.entries(extra).map(([key, value]) => [
                  `${key[0].toLowerCase()}${key.slice(1)}`
                    .replace('__c', '')
                    .replace('name', 'title')
                    .replace('units', 'participants'),
                  value,
                ])
              ) as ExcursionOptionType
          ),
        },
      }
    }
    case FETCH_METHODS_SUCCESS: {
      return {
        isFetching: false,
        fetchingError: null,
        data: {
          ...state.data,
          // eslint-disable-next-line no-underscore-dangle
          paymentMethods: action.payload.data._embedded.methods as PaymentMethodType[],
        },
      }
    }
    //   case SELECT_SUMMARY_ITEM_SUCCESS: {
    //     const { optionId } = action.meta.previousAction.payload.request.data

    //     const updatedItems =
    //       state.data.summary &&
    //       state.data.summary.map((summaryItem: SummaryOptionType) => {
    //         if (summaryItem.id === optionId) {
    //           return {
    //             ...summaryItem,
    //             included: !summaryItem.included,
    //           }
    //         }
    //         return summaryItem
    //       })

    //     return {
    //       isFetching: false,
    //       fetchingError: null,
    //       data: {
    //         ...state.data,
    //         summary: updatedItems,
    //       },
    //     }
    //   }

    case CREATE_PAYMENT: {
      return {
        ...state,
        isFetching: true,
      }
    }

    default: {
      return state
    }
  }
}

export default Quote
