import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container, useScreenClass } from 'react-grid-system'
import Moment from 'react-moment'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import Box from 'styles/Box'
import { sameDay } from 'helpers/sameDay'
import { H3, H4 } from 'styles/Heading'
import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'
import Button from 'styles/Button'
import Alert from 'styles/Alert'
import Text from 'styles/Text'

import { QuoteDataType, ExcursionType, ExcursionOptionType } from 'types'
import { ApplicationState } from 'reducers'
import OptionCard from 'components/OptionCard'

const Excursions: React.FC = () => {
  const { itineraryId }: any = useParams()
  const { excursions, itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)

  if (!itinerary) {
    return null
  }

  return (
    <Container>
      {!excursions.length && (
        <Box m={0} mt="2rem" mb="3rem">
          <Alert>
            Aan deze reis zijn geen excursies toegevoegd. Wilt u een excursie boeken, laat het ons dan vooral weten!
          </Alert>
        </Box>
      )}

      {excursions.length > 0 && (
        <Box m={0} mt="2rem" mb="1rem">
          {itinerary?.status === 'open' && (
            <Box m={0} mt="4rem" mb="1rem">
              <p>Op zoek naar andere excursies? Laat het ons weten dan passen we dit graag aan in de offerte.</p>
            </Box>
          )}

          {excursions.map((excursion: ExcursionType, index: number) => {
            const days = moment(excursion.endDate).diff(excursion.startDate, 'days') + 1
            const hasExcursions = excursion.options.filter(({ isInsurance }) => !isInsurance).length > 0

            const startDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!excursion.startDate) return false
                return sameDay(new Date(dayDate), new Date(excursion.startDate))
              }) + 1

            let endDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!excursion.endDate) return false
                return sameDay(new Date(dayDate), new Date(excursion.endDate))
              }) + 1
            if (!endDayIndex) endDayIndex = itinerary.days.length
            const daysDifference = endDayIndex - (startDayIndex - 1)

            return (
              <React.Fragment key={index}>
                {hasExcursions && (
                  <>
                    <H3>
                      {!itinerary.hideDates && (
                        <>
                          <Moment format="D MMMM">{excursion.startDate}</Moment>
                          {days > 1 && (
                            <>
                              <span> - </span>
                              <Moment format="D MMMM YYYY">{excursion.endDate}</Moment>
                              <span> </span>
                              <Text color="neutral" variant="light" inline>
                                ({days} dag{days !== 1 && 'en'})
                              </Text>
                            </>
                          )}
                        </>
                      )}
                      {itinerary.hideDates && (
                        <>
                          Dag {startDayIndex}
                          {daysDifference > 1 && (
                            <>
                              <span> - </span>
                              Dag {endDayIndex}
                              <span> </span>
                              <Text color="neutral" variant="light" inline>
                                ({daysDifference} dag{daysDifference !== 1 && 'en'})
                              </Text>
                            </>
                          )}
                        </>
                      )}
                    </H3>
                  </>
                )}

                {excursion.options.map((option: ExcursionOptionType) => {
                  return <OptionCard type="excursie" {...option} key={option.id} optionId={excursion.id} />
                })}
              </React.Fragment>
            )
          })}
        </Box>
      )}

      <Box m={0} mt="1rem" mb="8rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={`/${itineraryId}/accommodations`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Accommodaties
            </Button>
          </Link>
        </Box>

        <Link to={`/${itineraryId}/rentals`}>
          <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
            Vervoer
          </Button>
        </Link>
      </Box>
    </Container>
  )
}

export default Excursions
