import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { Hidden, Row, Col, useScreenClass } from 'react-grid-system'

import { createNotification } from 'actions/Notifications'
import { selectExcursionOrExtra, fetchQuote } from 'actions/Quote'
import currency from 'helpers/currency'
import useCompany from 'hooks/useCompany'

import Text from 'styles/Text'
import Box from 'styles/Box'
import { H2 } from 'styles/Heading'
import { InfoIcon } from 'styles/Icon'
import Alignment from 'styles/Alignment'
import Tooltip from 'styles/Tooltip'
import SidebarCard from 'styles/SidebarCard/SidebarCard'
import LabelButton from 'styles/LabelButton/LabelButton'
import SelectedButton from 'styles/SelectedButton/SelectedButton'
import Separator from 'styles/Separator'

import { ExcursionOptionType, QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'

import Dropdown from '../Dropdown/Dropdown'
import { LocationLabel } from '../LocationLabel/LocationLabel'

interface ExtendedOptionType extends ExcursionOptionType {
  optionId: string
  type: 'excursie' | 'extra'
}

const ImageCol = styled(Col)`
  height: 8rem;
  overflow: hidden;
`

const ButtonCol = styled(Col)`
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  border-left: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 991px) {
    border: none;
    padding-bottom: 2rem !important;
    text-align: left;
  }
`

const CenterMobile = styled.div`
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
`

const InfoCol = styled(Col)`
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 2rem !important;
  margin-bottom: 1rem;
  @media (min-width: 991px) {
    padding-bottom: 2rem !important;
    margin-bottom: 0;
  }
`

const CardRow = styled(Row)`
  margin: 0 !important;
  @media (max-width: 991px) {
    & > *:last-child {
      padding-bottom: 2rem !important;
    }
  }
`

const NoteWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.color.neutral.base};
  background-color: ${({ theme }) => theme.color.neutral.lighter};
  font-weight: 600;
  font-size: 0.9rem;
`

const ButtonContainer = styled.div`
  margin: 0;
`

const OptionCard: React.FC<ExtendedOptionType> = ({
  id,
  image,
  title,
  description,
  quoteAddress,
  sellAmount,
  participants,
  inclusive,
  website,
  selected,
  optionId,
  type,
  adjustParticipants,
  note,
}: ExtendedOptionType) => {
  const dispatch = useDispatch()
  const itineraryId = useParams()
  const { company } = useCompany()
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl', 'xxl'].includes(screenClass)
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const shouldRenderAdditionalPrice = !itinerary?.hidePrices || (!!itinerary?.hidePrices && !!itinerary?.showUpsell)
  const onSelect = async (participantCount: number = participants) => {
    setIsSubmitting(true)
    const action = await dispatch(
      selectExcursionOrExtra(
        optionId,
        id,
        false,
        participantCount,
        adjustParticipants ? participantCount !== participants : false
      )
    )
    setIsSubmitting(false)

    if (!action.payload || action.payload.status !== 200) {
      dispatch(
        createNotification({
          content: `${
            type === 'excursie' ? 'Excursie' : 'Extra'
          } selectie mislukt. Neem contact met ons op om uw voorkeur door te geven.`,
          status: 'danger',
        })
      )
    } else {
      dispatch(fetchQuote(itineraryId, company))
    }
  }

  function saveParticipantCount(count: number | any) {
    if (typeof count === 'number') {
      onSelect(count)
    } else onSelect()
  }
  return (
    <Box m={0} mt="2rem">
      <SidebarCard>
        <CardRow>
          <InfoCol lg={9}>
            <Row>
              <ImageCol lg={4}>
                <Hidden xs sm md>
                  <Box m={0} mr="2rem">
                    <img src={image} alt="" width="100%" />
                  </Box>
                </Hidden>
                <Hidden lg xl xxl>
                  <img src={image} alt="" width="100%" />
                </Hidden>
              </ImageCol>
              <Col lg={8}>
                <Alignment>
                  <div>
                    <Row align="center">
                      <Hidden xs sm md>
                        <Col width="fit-content">
                          <strong>{title}</strong>
                        </Col>
                      </Hidden>
                      <Hidden lg xl xxl>
                        <Box m={0} mb="1rem" mt="1rem">
                          <Col width="fit-content">
                            <H2 style={{ marginBottom: '1rem' }}>{title}</H2>
                          </Col>
                        </Box>
                      </Hidden>
                    </Row>
                    <LocationLabel quoteAddress={quoteAddress} />
                    <p>{description}</p>

                    {!!website && (
                      <CenterMobile>
                        <Box m={0} mt="1rem">
                          <LabelButton
                            small
                            outline
                            type="neutral"
                            onClick={() => {
                              window?.open(website, '_blank')?.focus()
                            }}
                          >
                            Meer informatie
                          </LabelButton>
                        </Box>
                      </CenterMobile>
                    )}
                    <Hidden lg xl xxl>
                      <Separator></Separator>
                    </Hidden>
                  </div>
                </Alignment>
              </Col>
            </Row>
          </InfoCol>
          {itinerary && (
            <ButtonCol lg={3}>
              <Row style={{ padding: 0 }}>
                <Col style={{ padding: 0 }}>
                  <Alignment justify={!isDesktop ? 'flex-start' : 'center'}>
                    {!inclusive && (
                      <>
                        {!adjustParticipants && selected && itinerary?.status === 'open' && (
                          <ButtonContainer>
                            <SelectedButton disabled={isSubmitting} onClick={saveParticipantCount} />
                          </ButtonContainer>
                        )}

                        {adjustParticipants && !inclusive && participants > 0 && itinerary?.status === 'open' && (
                          <ButtonContainer>
                            <Dropdown
                              disabled={isSubmitting}
                              participantCount={selected ? participants : 0}
                              setParticipantCount={saveParticipantCount}
                            ></Dropdown>
                          </ButtonContainer>
                        )}

                        {!adjustParticipants && !selected && itinerary?.status === 'open' && (
                          <ButtonContainer>
                            <LabelButton type="primary" onClick={saveParticipantCount} small>
                              Selecteren
                            </LabelButton>
                          </ButtonContainer>
                        )}

                        {!selected && itinerary?.status === 'expired' && (
                          <>
                            <Hidden xs sm md>
                              <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                                <span>
                                  <LabelButton type="neutral" disabled small>
                                    Selecteren
                                  </LabelButton>
                                </span>
                              </Tooltip>
                            </Hidden>
                            <Hidden lg xl xxl>
                              <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                                <Box m={0}>
                                  <LabelButton type="neutral" disabled small>
                                    Selecteren
                                  </LabelButton>
                                </Box>
                              </Tooltip>
                            </Hidden>
                          </>
                        )}
                        {selected && itinerary?.status === 'expired' && (
                          <>
                            <SelectedButton disabled />
                          </>
                        )}
                      </>
                    )}

                    {!!inclusive && (
                      <Box m={0} align="center">
                        <Text color="success">Deze {type} is bij het reisaanbod inbegrepen</Text>
                      </Box>
                    )}

                    {!inclusive && itinerary?.status === 'booked' && !selected && (
                      <Box m={0} align="center">
                        <Text color="danger">Deze {type} is niet bij het reisaanbod inbegrepen</Text>
                      </Box>
                    )}

                    {!inclusive && itinerary?.status === 'booked' && selected && !adjustParticipants && (
                      <SelectedButton />
                    )}
                    {!inclusive &&
                      itinerary?.status === 'booked' &&
                      selected &&
                      adjustParticipants &&
                      participants > 0 && (
                        <Dropdown
                          disabled
                          participantCount={participants}
                          setParticipantCount={saveParticipantCount}
                        ></Dropdown>
                      )}
                  </Alignment>
                </Col>
              </Row>
              <Row>
                <Col>
                  {shouldRenderAdditionalPrice && !!sellAmount && !inclusive && itinerary?.status === 'open' && (
                    <Box m={0} mb="1rem" mt="1rem" align="center">
                      <Text color="neutral">
                        {selected ? (
                          <strong>- {currency.format(sellAmount / (participants || 1))} per persoon</strong>
                        ) : (
                          <strong>+ {currency.format(sellAmount / (participants || 1))} per persoon</strong>
                        )}
                      </Text>
                    </Box>
                  )}
                </Col>
              </Row>
            </ButtonCol>
          )}
        </CardRow>
        {!!note && (
          <NoteWrapper>
            <Hidden xs>
              <Box m={0} ml={0} mr="1rem" inline>
                <InfoIcon color="neutral" variant="base" />
              </Box>
            </Hidden>
            {note}
          </NoteWrapper>
        )}
      </SidebarCard>
    </Box>
  )
}

export default OptionCard
