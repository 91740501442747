import React from 'react'

import { StarIcon } from 'styles/Icon'

type RatingProps = {
  rating: 0 | 1 | 2 | 3 | 4 | 5
  size?: string
}

const Rating: any = ({ rating, size }: RatingProps) =>
  Array.from({ length: 5 }).map((_, index) => (
    <StarIcon
      key={index}
      color={index < rating ? 'primary' : 'neutral'}
      variant={index < rating ? 'base' : 'lighter'}
      size={size}
    />
  ))

Rating.defaultProps = {
  rating: 0,
}

export default Rating
