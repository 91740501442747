import React from 'react'
import Icon, { IconType } from '../Icon'

export const PinIcon: React.FC<IconType> = (props) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M6.5 10.5C6.5 7 9 4.5 12.5 4.5C16 4.5 18.5 7 18.5 10.5C18.5 14 15.5 17.5 12.5 20.5C9.5 17.5 6.5 14 6.5 10.5Z"
      strokeWidth="1.2"
    />
    <path
      d="M14 10.5C14 11.3284 13.3284 12 12.5 12C11.6716 12 11 11.3284 11 10.5C11 9.67157 11.6716 9 12.5 9C13.3284 9 14 9.67157 14 10.5Z"
      strokeWidth="1.2"
    />
  </Icon>
)
