import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import styled from 'styled-components'
import { Row, Col, Hidden } from 'react-grid-system'

import { QuoteDataType, RentalOptionType } from 'types'

import { selectRental, fetchQuote } from 'actions/Quote'
import { createNotification } from 'actions/Notifications'
import useCompany from 'hooks/useCompany'

import currency from 'helpers/currency'

import Box from 'styles/Box'
import Button from 'styles/Button'
import { H2, H3 } from 'styles/Heading'
import Text from 'styles/Text'
import { CheckIconRounded, InfoIcon } from 'styles/Icon'
import Label from 'styles/Label'
import Alignment from 'styles/Alignment'
import Tooltip from 'styles/Tooltip'
import Card from 'styles/Card'
import { ApplicationState } from 'reducers'
import SidebarCard from 'styles/SidebarCard'
import LabelButton from 'styles/LabelButton'
import SelectedButton from 'styles/SelectedButton'
import Separator from 'styles/Separator'
import { LocationLabel } from '../../components/LocationLabel/LocationLabel'

const RentalTitle = styled(H3)`
  margin-bottom: 0;
`

const ButtonDiv = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface ExtendedRentalOptionType extends RentalOptionType {
  orderedByAmount: any
  rentalId: string
  duration: number
}

const RentalOption: React.FC<ExtendedRentalOptionType> = ({
  id,
  image,
  title,
  note,
  selected,
  inclusives,
  exclusives,
  duration,
  sellAmount,
  orderedByAmount,
  quoteAddress,
  rentalId,
}: ExtendedRentalOptionType) => {
  const dispatch = useDispatch()
  const { itineraryId }: any = useParams()
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { company } = useCompany()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const lowestmount = orderedByAmount[0].sellAmount
  // const additionalAmount = sellAmount - lowestmount
  const selectedOption = orderedByAmount.filter((rental: any) => rental.selected === true)
  const priceDifference = sellAmount - selectedOption[0].sellAmount
  const shouldRenderAdditionalPrice = !itinerary?.hidePrices || (!!itinerary?.hidePrices && !!itinerary?.showUpsell)
  const onSelectRental = async () => {
    setIsSubmitting(true)
    const action = await dispatch(selectRental(rentalId, id))
    setIsSubmitting(false)

    if (!action.payload || action.payload.status !== 200) {
      dispatch(
        createNotification({
          content: 'Vervoer selectie mislukt. Neem contact met ons op om uw voorkeur door te geven.',
          status: 'danger',
        })
      )
    } else {
      dispatch(fetchQuote(itineraryId, company))
    }
  }
  return (
    <SidebarCard key={id}>
      <Row style={{ margin: 0 }}>
        <Col lg={9}>
          <Row style={{ padding: '2rem' }}>
            <Col lg={2}>
              <img src={image} alt="" width="100%" />
            </Col>
            <Col>
              {!!quoteAddress && (
                <Box m={0} mb="0.3rem">
                  <LocationLabel quoteAddress={quoteAddress} />
                </Box>
              )}
              <Row>
                <Col lg={9}>
                  <Alignment>
                    <Hidden xs sm md>
                      <Box m={0} mr="0.5rem">
                        <RentalTitle>{title}</RentalTitle>
                      </Box>
                    </Hidden>
                    <Hidden lg xl xxl>
                      <Box m={0}>
                        <H2>{title}</H2>
                      </Box>
                    </Hidden>

                    {!!note && (
                      <Hidden xs sm md>
                        <Tooltip content={note}>
                          <span>
                            <InfoIcon size="1rem" color="neutral" />
                          </span>
                        </Tooltip>
                      </Hidden>
                    )}
                  </Alignment>
                </Col>

                {inclusives && inclusives.length > 0 && (
                  <Col lg={6} md={12}>
                    <strong>Inclusief:</strong>
                    {inclusives && inclusives.length && (
                      <ul>
                        {inclusives.map((item, inclusiveIndex) => (
                          <li key={inclusiveIndex}>{item}</li>
                        ))}
                      </ul>
                    )}
                  </Col>
                )}

                {exclusives && exclusives.length > 0 && (
                  <Col lg={6} md={12}>
                    <strong>Exclusief:</strong>
                    {exclusives && exclusives.length && (
                      <ul>
                        {exclusives.map((item, exclusiveIndex) => (
                          <li key={exclusiveIndex}>{item}</li>
                        ))}
                      </ul>
                    )}
                  </Col>
                )}

                <Hidden lg xl xxl>
                  <ButtonDiv>
                    <Separator both></Separator>

                    {selected && <SelectedButton />}

                    {!selected && (
                      <Box m={0} mt="1rem" mb="1rem">
                        {itinerary?.status === 'open' && (
                          <LabelButton type="primary" onClick={onSelectRental} disabled={isSubmitting} small>
                            Selecteren
                          </LabelButton>
                        )}
                        {itinerary?.status === 'expired' && (
                          <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                            <span>
                              <LabelButton type="neutral" disabled small>
                                Selecteren
                              </LabelButton>
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    )}
                    {shouldRenderAdditionalPrice && !!priceDifference && itinerary?.status === 'open' && (
                      <Box m={0} mt="0.5rem" mb="1rem">
                        <Text color="neutral">
                          {priceDifference > 0.0 ? (
                            <strong>+ {currency.format(priceDifference / (duration || 1))} per dag</strong>
                          ) : (
                            <strong>- {currency.format(Math.abs(priceDifference) / (duration || 1))} per dag</strong>
                          )}
                        </Text>
                      </Box>
                    )}
                  </ButtonDiv>
                </Hidden>
              </Row>
            </Col>
          </Row>
        </Col>

        <Hidden xs sm md>
          <Col
            lg={3}
            style={{ padding: '2rem', borderLeft: '1px solid #ebebeb', display: 'flex', justifyContent: 'center' }}
          >
            {selected && <SelectedButton disabled />}
            {!selected && (
              <Alignment justify="center">
                <Box m={0} mt="1rem" mb="1rem">
                  {itinerary?.status === 'open' && (
                    <LabelButton type="primary" onClick={onSelectRental} disabled={isSubmitting} small>
                      Selecteren
                    </LabelButton>
                  )}
                  {itinerary?.status === 'expired' && (
                    <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                      <span>
                        <LabelButton type="neutral" onClick={onSelectRental} disabled small>
                          Selecteren
                        </LabelButton>
                      </span>
                    </Tooltip>
                  )}

                  {shouldRenderAdditionalPrice && !!priceDifference && itinerary?.status === 'open' && (
                    <Box m={0} mt="1rem" mb="1rem">
                      <Text color="neutral">
                        {priceDifference > 0.0 ? (
                          <strong>+ {currency.format(priceDifference / (duration || 1))} per dag</strong>
                        ) : (
                          <strong>- {currency.format(Math.abs(priceDifference) / (duration || 1))} per dag</strong>
                        )}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Alignment>
            )}
          </Col>
        </Hidden>
      </Row>
    </SidebarCard>
  )
}

export default RentalOption
