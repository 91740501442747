import React from 'react'
import styled from 'styled-components'
import { PinIcon } from '../../styles/Icon/icons/PinIcon'

const Container = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: -0.3rem;
    margin-right: 0.2rem;
  }
  span {
    color: ${({ theme }) => theme.color.primary.base};
  }
`

interface LocationLabelProps {
  quoteAddress?: string
  variant?: 'base' | 'dark' | 'light' | 'lighter'
  color?: 'primary' | 'neutral' | 'success' | 'danger'
}

export const LocationLabel = ({ quoteAddress, color = 'primary', variant = 'base' }: LocationLabelProps) => {
  if (!quoteAddress) {
    return null
  }

  return (
    <Container>
      <PinIcon color={color} variant={variant} outline />
      <span>{quoteAddress}</span>
    </Container>
  )
}
