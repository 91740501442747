import React from 'react'
import { useSelector } from 'react-redux'
import { Container, useScreenClass } from 'react-grid-system'
import Moment from 'react-moment'

import { Link, useParams } from 'react-router-dom'
import { sameDay } from 'helpers/sameDay'
import { QuoteDataType, RentalType, RentalOptionType } from 'types'
import { ApplicationState } from 'reducers'

import Box from 'styles/Box'
import Button from 'styles/Button'
import { H3, H4 } from 'styles/Heading'
import Text from 'styles/Text'
import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'
import Alignment from 'styles/Alignment'
import Alert from 'styles/Alert'

import RentalOption from './RentalOption'

const Rentals: React.FC = () => {
  const { itineraryId }: any = useParams()
  const { rentals, itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)

  if (!itinerary) {
    return null
  }

  return (
    <Container>
      {!rentals.length && (
        <Box m={0} mt="2rem" mb="3rem">
          <Alert>
            Aan deze reis zijn geen vervoersmiddelen toegevoegd. Wilt u een vervoersmiddel huren, laat het ons dan
            vooral weten!
          </Alert>
        </Box>
      )}

      {rentals.length > 0 && (
        <>
          {itinerary?.status === 'open' && (
            <Box m={0} mt="4rem" mb="1rem">
              <p>Liever een ander voertuig huren? Laat het ons weten dan passen we dit graag aan in de offerte.</p>
            </Box>
          )}

          {rentals.map((rental: RentalType, index: number) => {
            const startDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!rental.startDate) return false
                return sameDay(new Date(dayDate), new Date(rental.startDate))
              }) + 1

            let endDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!rental.endDate) return false
                return sameDay(new Date(dayDate), new Date(rental.endDate))
              }) + 1
            if (!endDayIndex) endDayIndex = itinerary.days.length

            const daysDifference = endDayIndex - startDayIndex
            return (
              <React.Fragment key={index}>
                <Box m={0} mr="0.5rem" inline>
                  <Alignment v="baseline">
                    {!itinerary.hideDates && (
                      <>
                        <Box m={0} mr="0.5rem" mt="2rem">
                          <H3>
                            <Moment format="D MMMM">{rental.startDate}</Moment>
                            <span> - </span>
                            <Moment format="D MMMM YYYY">{rental.endDate}</Moment>
                          </H3>
                        </Box>
                      </>
                    )}

                    {itinerary.hideDates && (
                      <>
                        <Box m={0} mr="0.5rem" mt="2rem">
                          <H3>
                            Dag {startDayIndex}
                            <>
                              <span> - </span>
                              Dag {endDayIndex}
                            </>
                          </H3>
                        </Box>
                      </>
                    )}

                    <Text color="neutral" variant="light" inline>
                      ({rental.duration} dag{rental.duration !== 1 && 'en'})
                    </Text>
                  </Alignment>
                </Box>
                {rental.options.map((option: RentalOptionType) => {
                  const orderedByAmount = [...rental.options].sort((a, b) => a.sellAmount - b.sellAmount)

                  return (
                    <RentalOption
                      key={option.id}
                      rentalId={rental.id}
                      orderedByAmount={orderedByAmount}
                      duration={rental.duration}
                      {...option}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </>
      )}

      <Box m={0} mt="1rem" mb="8rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={`/${itineraryId}/excursions`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Excursies
            </Button>
          </Link>
        </Box>

        <Link to={`/${itineraryId}/extras`}>
          <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
            Extra's
          </Button>
        </Link>
      </Box>
    </Container>
  )
}

export default Rentals
