import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Col as DefaultCol, Row, Hidden, useScreenClass } from 'react-grid-system'

import { selectAccommodation, fetchQuote } from 'actions/Quote'
import { createNotification } from 'actions/Notifications'
import useCompany from 'hooks/useCompany'

import currency from 'helpers/currency'

import { StarIcon, InfoIcon } from 'styles/Icon'
import Tooltip from 'styles/Tooltip'
import Rating from 'styles/Rating'
import { H2 } from 'styles/Heading'

import Box from 'styles/Box'
import Alignment from 'styles/Alignment'
import Text from 'styles/Text'

import { AccommodationOptionType, QuoteDataType } from 'types'
import { ApplicationState } from 'reducers'
import SidebarCard from 'styles/SidebarCard/SidebarCard'
import SelectedButton from 'styles/SelectedButton'
import LabelButton from 'styles/LabelButton'
import Separator from 'styles/Separator'
import { LocationLabel } from '../../components/LocationLabel/LocationLabel'
import { useScreenSize } from '../../hooks/useScreenSize'

const Col = styled(DefaultCol)`
  align-content: center;
  align-items: center;
  display: flex;
`

const ImageCol = styled(Col)`
  height: 8rem;
  overflow: hidden;
  align-content: center;
  align-items: center;
`

const Note = styled.div`
  position: absolute;
  z-index: 1000;
  width: 0;
  height: 0;
  border-top: 5rem solid ${({ theme }) => theme.color.primary.base};
  border-bottom: 5rem solid transparent;
  border-right: 5rem solid transparent;

  & svg {
    position: absolute;
    margin-top: -3.9rem;
    margin-left: 1rem;
    transform: rotate(30deg);
  }
`

const ButtonCol = styled(Col)`
  padding: 2rem !important;
  padding-left: calc(2rem + 15px) !important;
  padding-top: 0px !important;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 991px) {
    border-left: 1px solid #ebebeb;
    padding-top: 2rem !important;
    padding-left: 2rem !important;
    align-items: center;
  }
`

const InfoCol = styled(Col)`
  @media (max-width: 991px) {
    padding-bottom: 0 !important;
  }
`

interface ExtendedAccommodationOptionType extends AccommodationOptionType {
  orderedByAmount: any
  accommodationId: string
}

const AccommodationOption: React.FC<ExtendedAccommodationOptionType> = ({
  orderedByAmount,
  sellAmount,
  accommodationId,
  id,
  image,
  title,
  description,
  quoteAddress,
  note,
  rooms,
  rating,
  website,
  room,
  mealplan,
  selected,
}: ExtendedAccommodationOptionType) => {
  const dispatch = useDispatch()
  const { itineraryId }: any = useParams()
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { company } = useCompany()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const lowestmount = orderedByAmount[0].sellAmount
  // const additionalAmount = sellAmount - lowestmount
  const selectedOption = orderedByAmount.filter((acco: any) => acco.selected === true)
  const priceDifference = sellAmount - selectedOption[0].sellAmount
  const shouldRenderAdditionalPrice = !itinerary?.hidePrices || (!!itinerary?.hidePrices && !!itinerary?.showUpsell)
  const screenSize = useScreenSize()

  const onSelectAccommodation = async () => {
    setIsSubmitting(true)
    const action = await dispatch(selectAccommodation(accommodationId, id))
    setIsSubmitting(false)
    if (!action.payload || action.payload.status !== 200) {
      dispatch(
        createNotification({
          content: 'Accommodatie selectie mislukt. Neem contact met ons op om uw voorkeur door te geven.',
          status: 'danger',
        })
      )
    } else {
      dispatch(fetchQuote(itineraryId, company))
    }
  }

  return (
    <div>
      {!!note && (
        <Tooltip content={note}>
          <Note>
            <StarIcon size="1rem" />
          </Note>
        </Tooltip>
      )}
      <SidebarCard key={id}>
        <Row>
          <InfoCol
            style={{
              flexWrap: 'wrap',
              marginLeft: '0',
              marginRight: '-15px',
              paddingRight: '0px',
              padding: '2rem',
            }}
            lg={9}
          >
            <ImageCol style={{ paddingRight: '0px', marginBottom: '10px' }} lg={3}>
              <Hidden xs sm md>
                <Box m={0}>
                  <img src={image} alt="" width="100%" />
                </Box>
              </Hidden>
              <Hidden lg xl xxl>
                <img src={image} alt="" width="100%" />
              </Hidden>
            </ImageCol>
            <Col lg={3}>
              <div>
                <Hidden lg xl xxl>
                  <div style={{ height: '0.3rem' }} />
                </Hidden>
                {!!quoteAddress && (
                  <Box m={0} pb={screenSize.isMobile ? '0.6rem' : '0.2rem'}>
                    <LocationLabel quoteAddress={quoteAddress} color="primary" variant="base" />
                  </Box>
                )}
                <Alignment>
                  <Hidden xs sm md>
                    <Box m={0} mr="0.5rem" mb="0.2rem">
                      <strong>{title}</strong>
                    </Box>
                  </Hidden>
                  <Hidden lg xl xxl>
                    <Box m={0} mb="0.6rem">
                      <H2>{title}</H2>
                    </Box>
                  </Hidden>

                  {!!description && (
                    <Hidden xs sm md>
                      <Tooltip content={description}>
                        <span>
                          <InfoIcon size="1rem" color="neutral" />
                        </span>
                      </Tooltip>
                    </Hidden>
                  )}
                </Alignment>
                {!!rating && (
                  <div>
                    <Rating rating={rating} />
                  </div>
                )}
                {!!website && (
                  <Box m={0} mt="0.5rem" mb="0.5rem">
                    <LabelButton
                      small
                      outline
                      type="neutral"
                      onClick={() => {
                        window?.open(website, '_blank')?.focus()
                      }}
                    >
                      Website
                    </LabelButton>
                  </Box>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <Alignment>
                <div>
                  <strong>Type kamer</strong>
                  {room ? <p>{room}</p> : <p>n.v.t.</p>}
                </div>
              </Alignment>
            </Col>
            <Col lg={3}>
              <Alignment>
                <div>
                  <strong>Basis</strong>
                  {mealplan ? <p>{mealplan}</p> : <p>n.v.t.</p>}
                </div>
              </Alignment>
            </Col>
          </InfoCol>
          <ButtonCol lg={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Hidden lg xl xxl>
              <Separator top></Separator>
            </Hidden>
            {selected && <SelectedButton />}

            {!selected && (
              <>
                <Hidden xs sm md>
                  <Alignment style={{ height: 'initial' }} justify="center">
                    {itinerary?.status === 'open' && (
                      <LabelButton type="primary" onClick={onSelectAccommodation} disabled={isSubmitting} small>
                        Selecteren
                      </LabelButton>
                    )}
                    {itinerary?.status === 'expired' && (
                      <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                        <span>
                          <LabelButton type="neutral" disabled small>
                            Selecteren
                          </LabelButton>
                        </span>
                      </Tooltip>
                    )}
                  </Alignment>
                </Hidden>
                <Hidden lg xl xxl>
                  {itinerary?.status === 'open' && (
                    <LabelButton type="primary" onClick={onSelectAccommodation} disabled={isSubmitting} small>
                      Selecteren
                    </LabelButton>
                  )}
                  {itinerary?.status === 'expired' && (
                    <Tooltip content="Deze offerte is verlopen. Neem contact met uw reisadviseur op om deze offerte bij te werken.">
                      <span>
                        <LabelButton type="neutral" disabled small outline>
                          Selecteren
                        </LabelButton>
                      </span>
                    </Tooltip>
                  )}
                </Hidden>
              </>
            )}

            {shouldRenderAdditionalPrice && !!priceDifference && itinerary?.status === 'open' && (
              <Box align="center" m={0} mt="1rem">
                <Text color="neutral">
                  {priceDifference > 0.0 ? (
                    <strong>+ {currency.format(priceDifference / (rooms || 1))} per kamer</strong>
                  ) : (
                    <strong>- {currency.format(Math.abs(priceDifference) / (rooms || 1))} per kamer</strong>
                  )}
                </Text>
              </Box>
            )}
          </ButtonCol>
        </Row>
      </SidebarCard>
    </div>
  )
}

export default AccommodationOption
